.home-collage {
    /* border: solid 2px red; */
    height: 500px;
}

.home-collage video, .home-collage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
}

.showcase-itm {
    /* border: solid 1px red; */
    padding: 5%;
    display: flex;
    align-items: center;
}

.showcase-img {
    /* width: 65%; */
    height: 50px;
}

.company-collage {
    /* background-color: darkgray; */
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    height: 200px;
}

.company-collage-img {
    width: 40%;
    max-width: 150px;
    /* height: 40px; */
}

#p107-container {
    /* border: solid 3px green; */
    display: flex;
    flex-flow: column nowrap;
    height: 300px;
    /* gap: 15px; */
    align-items: center;
    justify-content: center;
}

.p107-txt, .p107-img {
    /* border: solid 1px red; */
    flex: 1;
}

.p107-img {
    background-image: url('../../public/faa-certified-rectangle-img.png');
    /* border: solid 5px orange; */
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    width: 85%;
    background-size: contain;
}

.strong {
    color: #C28D48;
    font-weight: bold;
    font-size: large;
}

@media (min-width: 800px) {
    .showcase-tri {
        flex-direction: row;
    }
    .showcase-itm {
        width: 33%;
    }

    .home-collage {
        height: auto;
    }
    #p107-title {
        width: 100%;
    }
    .p107-img {
        height: 150px;
    }
    #p107-container {
        flex-flow: row wrap;
        /* height: 300px; */
        /* align-items: center; */
    }
}