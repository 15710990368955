.form-container {
    width: 100%;
    /* height: 800px; */
}

form {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    padding: 3% 0;
    /* border: solid 1px red; */
    background-color: #ACAC97;
    align-items: center;
    margin: 5% auto;
    width: 95%;
    max-width: 600px;
    border-radius: 10px;
}

label {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 22px;
}

.form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 10px 0;
}

.form-input, .form-select {
    font-family: 'Roboto Slab', serif;
    height: 30px;
    font-size: 20px;
    border-radius: 10px;
    width: 90%;
    margin: auto;
}

.form-textarea {
    width: 90%;
    border-radius: 10px;
    font-size: 20px;
    margin: auto;
}

.form-input::placeholder, .form-input {
    font-family: 'Roboto Slab', serif;
    text-align: center;
}

.form-select {
    text-align: center;
}

.form-select, .form-textarea, .form-input, label {
    color: #4F5855;
}

.submit-btn {
    width: 200px;
    height: 40px;
    align-self: center;
    background-color: #A45E42;
    color: #F6F5F5;
}

.disabled {
    background-color: #a45e422c;
    color: #f6f5f52f;
}

.error-container {
    /* border: solid 1px red; */
    width: 95%;
    max-width: 600px;
    margin: auto;
}

.error-message {
    color: red;
    /* margin: 5px; */
    height: 30px;
}

div {
    display: flex;
    flex-direction: column;
}