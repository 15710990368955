#services-container {
    width: 100vw;
}

.service-dropdown {
    margin-bottom: 2%;
}

.img-with-text {
    /* border: solid 1px black; */
    display: flex;
    /* height: 80vh; */
    width: 100%;
}

.services-img-container {
    /* border: solid 2px green; */
    width: 100%;
    /* height: 500px; */
    flex: 1;
    aspect-ratio: 1;
}

.img-text {
    /* border: solid 2px orange; */
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
}

.img1 {
    background-image: url('/public//topdown.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.img2 {
    background-image: url('/public//roof-structure.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.img3 {
    background-image: url('/public//floorplan.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.img4 {
    background-image: url('/public//frontface.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 800px) {
    .img-with-text {
        /* border: solid 1px violet; */
        flex-direction: row;
        max-width: 80%;
        margin: auto;
    }
    .img-text {
        width: 50%;
    }
    .services-img-container {
        width: 50%;
    }
    .img-with-text:nth-child(odd) {
        flex-direction: row-reverse;
    }
    /* .img-text p {
        border: solid 1px green;
        height: 40%;
        width: 50%;
    } */
}


/* .service-dropdown, #services-container h2 {
    text-align: center;
}

.img-with-text {
    margin: auto;
    border: solid 2px hotpink;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

.service-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: solid 2px greenyellow;
}

@media (min-width: 800px) {
    .img-with-text {
        flex-flow: row nowrap;
        width: 100%;
        margin-bottom: 2%;
    }
    .services-img-container {
        flex: 1;
        aspect-ratio: 1;
        border: solid 1px black;
    }
    .service-img {
        max-width: 100%;
        max-height: 100%;
    }
    .img-with-text p {
        border: solid 1px rebeccapurple;
        flex: 1;
        aspect-ratio: 1;
        padding: 0;
    }
    .img-with-text:nth-child(odd) {
        flex-direction: row-reverse;
    }
} */