/*
font-family: 'Bebas Neue', sans-serif;
font-family: 'Koulen', cursive;
font-family: 'Roboto Slab', serif;
*/

/* 
F6F5F5 - Light Shade
C28D48 - Light Accent
ACAC97 - Main
A45E42 - Dark Accent
4F5855 - Dark Shade
*/

p {
  padding: 0 15%;
  max-width: 600px;
  font-size: 1.1rem;
  /* text-align: left; */
  margin: 2% auto;
  color: #4F5855;
  line-height: normal;
}

h2 {
  font-family: 'Bebas Neue', sans-serif;
}

.App {
  text-align: center;
  margin: 65px 0 0 0;
  /* padding: 2%; */
  /* border: solid 5px cadetblue; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto Slab', serif;
}

header {
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  height: 70px;
  display: flex;
  width: 100%;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  /* padding: 0 10% 0 10%; */
  align-items: center;
  background-color: white;
  /* border: solid 5px black; */
  z-index: 999;
  /* padding: 1% 0; */
}

.logo {
  width: 80%;
  font-family: 'Bebas Neue', sans-serif;
}
.logo p {
  align-self: center;
}

.logo-footer {
  text-align: right;
}

.dropdown {
  width: 20%;
}

.dropdown-menu {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.dropdown-btn img {
  width: 30px;
  height: auto;
}

header h1 {
  margin: 0;
  width: 100%;
}

header p {
  margin: 0;
  color: #F6F5F5;
}

footer {
  /* position: relative;
  bottom: 0; */
  width: 100%;
  height: 200px;
  background-color: #A45E42;
  /* border: solid 5px pink; */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* border: solid 2px red; */
  height: 100%;
  /* width: 50%; */
  flex: 1;
  padding: 0 5%;
}

#footer-right {
  /* width: 50%; */
  flex: 1;
  /* border: solid 2px orange; */
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 5%;
  color: #F6F5F5;
}

#copyright {
  /* border: solid 2px red; */
  /* width: 100%; */
  margin: 10px 0;
  color: #F6F5F5;
  font-size: small;
}

.link {
  /* border: 1px solid black; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #F6F5F5;
  font-weight: bold;
}

.navlink {
  border-bottom: 1px solid #282c34;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  color: #282c34;
}

.ftr-link {
  font-weight: lighter;
  font-size: small;
}

section {
  width   : 100%;
  /* height  : 50px;    */
  position: relative;
  /* z-index : 1; */
  /* background: #eee; */
  padding-bottom: 5px;
  z-index: 1;
}

section:before {
  content : "";
  position: absolute;
  left: 25%;
  bottom  : 0;
  height  : 1px;
  width   : 50%;
  border-bottom:2px solid black;
}

.hide {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 800px) {
  header {
    max-width: 85%;
    left: 7.5%;
  }
  .home-collage {
    width: 80%;
    /* max-width: 80%; */
    margin: auto;
  }
  .dropdown {
    margin-right: 3%;
  }
  .dropdown-menu {
    width: 200px;
    margin-top: 0;
    right: 25%;
  }
  .App {
    /* flex-flow: row wrap;
    justify-content: center; */
    max-width: 85%;
    /* padding: 0 10%; */
    margin: 65px auto 0 auto;
    /* margin: auto; */
    /* border: solid 2px teal; */
  }
}

header {
  background-color: #ACAC97;
}

.logo, .dropdown-img {
  color: #F6F5F5;
}

.App {
  background-color: #F6F5F5;
}

.dropdown-menu {
  background-color: #ACAC97;
  border: solid 1px #4F5855;
}

.navlink {
  color: #F6F5F5;
}

.active {
  color: #C28D48;
  font-weight: bolder;
}

h2, h3, h4, h5 {
  color: #4F5855;
}